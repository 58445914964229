.Carousel {
	/* background-color: ; */
	height: 24vw;
	width: 90%;
	margin: 0 auto;
	/* padding: 0 10px 30px 10px; */
	/* color: #333; */
}

.slick-list {
	padding: 0px !important;
}

/* @media (min-width: 480px) {
	.Carousel {
		height: 25vh;
	}
}

@media (min-width: 768px) {
	.Carousel {
		height: 30vh;
	}
} */
