.address-modal-container {
	position: fixed;
	inset: 0;
	z-index: 6;
	background-color: rgba(0, 0, 0, 0.2);
	margin: auto;
}

.address-modal-container .address-input-container {
	position: absolute;
	inset: 0;
	background-color: rgb(255, 255, 255);
	margin: auto;
	width: 290px;
	height: 550px;
	text-align: center;
	border-radius: 10px;
}

.address-input-container h1 {
	margin-top: 25px;
	color: rgb(33, 33, 33);
	margin-bottom: 25px;
}

.address-modal-container .address-input-container input {
	padding: 8px 16px;
	display: block;
	width: 90%;
	margin: 12px auto;
	border: 1px solid rgb(226, 226, 226);
	border-radius: 5px;
	direction: rtl;
}

.address-modal-container .submit {
	color: white;
	background-color: var(--primary-color);
	font-weight: 600;
	display: inline;
}

.address-modal-container .submit:hover {
	cursor: pointer;
	background-color: black;
}

address-modal-container .button {
	color: white;
	background-color: var(--secondary-background-color);
	font-weight: 600;
	display: inline;
}
.address-modal-container .button:hover {
	/* color: white; */
	cursor: pointer;
	background-color: var(--secondary-background-color-onhover);
}

.address-modal-container .btn-container {
	display: flex;
	justify-content: center;
	padding: 0 20px;
	/* width: 90%; */
}

.address-modal-container .btn-container button {
	border: none;
	padding: 8px 16px;

	border-radius: 5px;
}

.address-modal-container .btn-container button:hover {
	cursor: pointer;
	background-color: var(--secondary-background-color-onhover);
}

.address-modal-container .btn-container button:last-child {
	flex-grow: 1;
	margin-left: 10px;
}

@media (min-width: 768px) {
	.address-modal-container .address-input-container {
		width: 390px;
	}
}
