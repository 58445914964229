.video-container {
	display: flex;
	background-color: rgb(255, 255, 255);
	justify-content: space-around;
	flex-flow: row wrap;
	margin: 0;
	margin-bottom: 10vw;
}

.video-card {
	width: 23vw;
	height: 30vw;
	margin-left: 3px;
}
/* box-shadow: 0px -1px 84px -16px rgba(117, 117, 117, 1); */

.video-card2 {
	cursor: pointer;
	/* position: relative; */
	display: block;
	flex-flow: column nowrap;
	height: 30vw;
	width: 23vw;
}
.photo-card {
	height: 100%;
	width: 100%;
	border-radius: 3px;
}

.notch {
	left: 23%;
	right: 0;
	text-align: center;
	position: absolute;
	background-color: rgb(27, 27, 27);
	height: 2.3vw;
	width: 52%;
	border-radius: 4px 4px 8px 8px;
}
.video {
	object-fit: cover;
	border-radius: 11px;
	opacity: 1;
}

.video-card .video-card2 h4 {
	/* font-size: 0.9em; */
	position: absolute;
	color: rgb(176, 175, 175); /* rgb(213, 254, 214);*/
	bottom: 30px;
	left: 0;
	right: 0;
	text-align: center;
	background-color: black;
	opacity: 0.8;
	border-radius: 2px;
	font-size: small;
}

.video-section-img {
	width: 100%;
	position: relative;
}

.hero-img-container {
	background-color: black;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.hero-img-container img {
	opacity: 0.6;
}

.hero-img-container h1 {
	opacity: 0.9;
	text-align: center;
	font-size: 3rem;
	padding: 10px;
	color: white;
	position: absolute;
	top: 5rem;
}

@media (min-width: 768px) {
	.video-container {
		margin-bottom: 1rem;
		justify-content: center;
		height: 36vw;
	}
	.video-card {
		height: 34vw;
		width: 22vw;
		margin: auto 30px;
		display: flex;
		align-items: center;
	}
	.video-card .video-card2 {
		height: 25vw;
		width: 15vw;
	}

	.notch {
		height: 1.3vw;
	}

	.video-card .video-card2 h4 {
		font-size: large;
	}

	.hero-img-container h1 {
		font-size: 6em;
	}
}

@media (min-width: 1440px) {
	.video-container {
		margin-top: 4rem;
		margin-bottom: 6rem;
		justify-content: center;
		height: 500px;
	}
	.video-card {
		height: 500px;
		width: 280px;
		margin: auto 20px;
		display: flex;
		align-content: stretch;
		justify-content: stretch;
	}
	.video-card .video-card2 {
		height: 450px;
		width: 300px;
	}

	.notch {
		height: 1.3vw;
	}

	.video-card .video-card2 h4 {
		font-size: large;
	}

	.hero-img-container h1 {
		font-size: 6em;
	}
}
