* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
h1 {
	font-size: 2rem;
	line-height: 1.3;
}

h2 {
	font-size: 1.5rem;
	line-height: 1.4;
}
.page-heading {
	text-align: center;
	margin: 20px;
}

@media (min-width: 768px) {
	h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 1.75rem;
	}
}

@media (min-width: 1024px) {
	h2 {
		font-size: 2rem;
	}
}
button {
	cursor: pointer;
}

:root {
	--primary-color: rgb(66, 66, 66);
	--primary-color-onhover: black;

	/* --secondary-background-color: rgb(232, 232, 232); */
	/* --secondary-background-color-onhover: rgb(214, 214, 214); */
}
.app {
	direction: rtl;
	background-color: #fff !important;
}

.main_btn_bk {
	background-color: #00a99d !important;
}
.main_btn_bk:hover {
	background-color: #01625d !important;
}

.navBar-bk {
	/* background-color: #f5f7f9 !important; */
	background-color: #f5f7f9 !important;
}

.main_text_color {
	color: #00a99d !important;
}

/* .main_text_color:hover {
	color: #01625d !important;
} */

.warning_bk {
	background-color: rgb(211, 62, 3) !important;
}

.warning_bk:hover {
	background-color: rgb(116, 3, 3) !important;
}

.warning_bk_not_available {
	background-color: rgb(211, 62, 3) !important;
}
.warning_bk_not_available:hover {
	cursor: auto !important;
}

.warning_text {
	color: rgb(211, 62, 3) !important;
}

.disabled-btn {
	background-color: #bbb !important;
	cursor: auto !important;
}

.text_222_color {
	color: #222 !important;
}

/* .warning_text:hover {
	background-color: rgb(116, 3, 3) !important;
} */
@media (min-width: 1440px) {
	:root {
		width: 1440px;
		margin: 0 auto;
		background-color: #fff;
	}
}
