.product-details-image {
	height: 100%;
	align-self: center;
	justify-self: center;
	margin-top: 20px;
}
.product-details-image img {
	width: 60vw;
	/* width: 90%; */
	object-fit: fill;
	height: 65vw;
	/* height: 90%; */
	margin-bottom: 30px;
}

@media (min-width: 768px) {
	.product-details-image {
		margin-top: 30px;
		margin-right: 30px;
		/* padding: 0px 40px; */
	}
	.product-details-image img {
		width: 25vw;
		/* width: 100%; */
		/* object-fit: cover; */
		height: 40vw;
		margin-top: -70px;
	}
}
