.order-details-container {
	color: rgb(0, 0, 0);
	/* background-color: rgb(242, 242, 242); */
	background-color: #f5f7f9;
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	border-radius: 10px;
	margin: 15px;
}

.no-address {
	color: red;
	text-align: center;
	margin-bottom: 30px;
}

@media (min-width: 768px) {
	.order-details-container {
		width: 50vw;
		flex-grow: 1;
		margin: 15px 15px 15px 0;
	}
}
