.page-container {
	margin-top: 1rem;
	direction: rtl;
	background-color: #fff !important;
}

@media (min-width: 768px) {
	.page-container {
		display: flex;
	}

	.products-container {
		flex-grow: 1;
	}
}
