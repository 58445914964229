.hero-heading-container {
	margin: 1.4rem auto 1.4rem auto;
	text-align: center;
}
.hero-title {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	font-size: 1.5em;
	/* background: #000000; */
	/* background: linear-gradient(to right, #000000 0%, #5e5e5e 100%); */
	/* -webkit-background-clip: text; */
	/* -webkit-text-fill-color: transparent; */
}

.hero-subtitle {
	margin: 1em 0 1em 0em;
	line-height: 1.5;
	font-size: 1em;
	color: grey;
}

@media (min-width: 768px) {
	.hero-title {
		font-size: 3em;
		color: #222;
	}

	.hero-subtitle {
		font-size: 1.3em;
	}
}
