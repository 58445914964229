.products-page-container .product-details-description {
	width: 70vw;
	/* height: 50vh; */
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-evenly;
	align-items: center;
	line-height: 25px;
}

.products-page-container .product-details-description h1 {
	margin-bottom: 2rem;
}
.gender-container {
	font-size: 0.9rem;
}

.description-container {
	font-size: 1rem;
	line-height: 22px;
	margin-bottom: 20px;
}

.description-container > span {
	font-weight: 600;
}

.gender-container > span {
	font-weight: 600;
	color: black;
}
.gender-container {
	color: grey;
}

.size-container {
	font-size: 0.9rem;
	color: grey;
}
.size-container > span {
	font-weight: bold;
	color: black;
}

.product-price-container {
	margin-bottom: 20px;
}

.product-original-price {
	font-size: 1.5rem;
	color: grey;
	text-decoration: line-through;
	text-decoration-color: red;
	font-weight: bold;
	margin-right: 10px;
}

.product-discount-price {
	font-size: 1.7rem;
	font-weight: bold;
}
.ratings-reviews {
	font-size: 0.9rem;
	margin-top: -20px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}

.ratings-reviews > * {
	margin-right: 5px;
}

.ratings-reviews > :first-child {
	font-weight: bold;
}
.ratings-reviews .review {
	color: grey;
}

.tags {
	display: flex;
	margin-bottom: 15px;
}
.tags > * {
	margin-right: 10px;
}

.product-card-buttons-container {
	display: flex;
	flex-flow: column nowrap;
	margin-bottom: 30px;
}

.product-card-buttons-container .add-to-cart-btn {
	font-weight: 800;
	border: none;
	margin-bottom: 8px;
	border-radius: 10px;
	font-size: 1em;
	color: white;
	background-color: var(--primary-color);
	padding: 15px 45px;
	text-transform: uppercase;
}

.product-card-buttons-container .add-to-cart-btn:hover {
	background-color: rgb(0, 0, 0);
	color: rgb(255, 255, 255);
	cursor: pointer;
}

.product-card-buttons-container .add-to-wishlist-btn {
	border: none;
	background-color: rgb(232, 232, 232);
	color: var(--primary-color);
	border-radius: 10px;
	padding: 15px 45px;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1em;
}

.product-card-buttons-container .add-to-wishlist-btn:hover {
	background-color: rgb(214, 214, 214);
	color: rgb(0, 0, 0);
	box-shadow: none;
}

@media (min-width: 768px) {
	.products-page-container .product-details-description {
		/* width: 25vw; */
		padding: 40px;
		height: 300px;
		justify-content: space-between;
		line-height: 26px;
		margin-top: -30px;
	}

	.tags {
		display: flex;
		margin-bottom: 30px;
	}
}
