.ordered-items-card {
	padding-left: 30px;
	padding-top: 15px;
	width: 90vw;
	display: flex;
	flex-flow: column nowrap;
	margin-bottom: 30px;
	/* border-bottom: 1px solid rgb(231, 231, 231); */
}
.ordered-items-card h3 {
	padding-bottom: 15px;
}
.products-card {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* color: grey; */
	margin-bottom: 5px;
}
.ordered-items-card img {
	width: 90px;
	margin-right: 20px;
}

.ordered-items-card .products-card .description {
	display: flex;
	flex-flow: column nowrap;
	align-self: center;
	margin: auto 10px;
	width: 50%;
}

.ordered-items-card .products-card .description .bookTitle {
	color: #222;
}
.ordered-items-card .products-card .description .authors {
	color: #888;
}
