.footer {
	color: black;
	text-align: center;
	/* display: block; */
	/* background-color: black; */
	background-color: #f5f7f9;
	display: flex;
	flex-flow: column nowrap;
	padding: 0.5rem;
	justify-content: space-between;
}
.footer > * {
	margin: 0.3rem;
}
.footer > :first-child {
	opacity: 0.5;
}

.social-links {
	display: flex;
	flex-grow: 1;
	justify-content: center;
}

.social-links > * {
	padding: 0 0.5rem;
	color: #222;
	/* color: rgb(175, 175, 175); */
}
.social-links > *:hover {
	/* color: white; */
	color: #00a99d !important;
}

@media (min-width: 768px) {
	.footer {
		padding: 0.5rem;
	}
	.footer small {
		font-size: 1rem;
	}

	.footer a {
		font-size: 1.5rem;
	}
}
