.login-container {
	padding: 2em;
	/* border: 1px solid rgb(228, 228, 228); */
	width: 350px;
	margin: auto;
	margin-top: 15vh;
	display: flex;
	flex-flow: column nowrap;
	height: 400px;
	border-radius: 10px;
	box-shadow: 0px 2px 33px 3px rgb(234, 234, 234);
}

.login-container > h2 {
	/* border: 1px solid black; */
	text-align: center;
	margin-bottom: 10px;
	color: #222;
}

.login-body {
	flex-grow: 1;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-around;
}

.login-container .email-container {
	/* border: 1px solid black; */
	display: flex;
	flex-flow: column nowrap;
}

.login-container .email-container > label {
	margin-bottom: 5px;
}

.login-container .email-container input {
	padding: 5px 10px;
	border-radius: 3px;
	border: 1px solid rgb(210, 210, 210);
}

.login-container .input-container {
	display: flex;
	align-items: center;
	font-size: 1rem;
}

.login-container .password-container {
	display: flex;
	flex-flow: column nowrap;
}

.login-container .password-container > label {
	margin-bottom: 5px;
}

.login-container .password-container .input-container {
	padding: 5px 10px;
	border-radius: 3px;
	border: 1px solid rgb(210, 210, 210);
}

.login-container .hide-show-password-eye {
	margin-right: 3px;
	color: rgb(127, 127, 127);
	cursor: pointer;
}

.remember-me-container {
	display: flex;
	flex-flow: column nowrap;
	cursor: pointer;
	font-size: 0.8rem;
	color: rgb(95, 95, 95);
}

.remember-me-container input {
	border-color: rgb(189, 189, 189);
	margin-right: 7px;
	margin-bottom: 10px;
	cursor: pointer;
}
.remember-me-container p {
	color: rgb(122, 76, 76);
}

.login-btn-container {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 30px;
}

.login-container .login-btn-container button {
	padding: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border: none;
	font-size: 1em;
	/* background-color: rgb(230, 230, 230); */

	/* color: var(--primary-color); */
}

.login-container .login-btn-container input {
	padding: 10px;
	border-radius: 5px;
	font-weight: 600;

	color: rgb(0, 0, 0);
	border: none;
	margin-bottom: 10px;
	color: white;

	background-color: var(--primary-color);
}

.login-container .login-btn-container button:hover {
	cursor: pointer;
	background-color: #00a99d !important;
	color: #f5f5f5;
}

.login-body > a {
	/* border: 1px solid black; */
	margin: 0 auto;
	font-size: 0.8rem;
	color: rgb(144, 144, 144);
	text-decoration: none;
	cursor: pointer;
}

.input-container {
	border: 1px solid black;
	display: flex;
	justify-content: space-between;
}

.login-body .error {
	color: red;
	font-size: 0.8rem;
	padding: 0;
}

.input-container input {
	border: none;
	flex-grow: 1;
}

.input-container input:focus {
	outline: none;
}

@media (min-width: 868px) {
	.login-container {
		width: 400px;
	}

	.remember-me-container {
		flex-flow: row nowrap;
		justify-content: space-between;
	}
}
