.user-orders-container {
	padding: 30px;
}

.user-ordered-items-card {
	display: flex;
	flex-flow: column nowrap;
	margin-bottom: 30px;
	border-bottom: 1px solid rgb(231, 231, 231);
}
.user-ordered-items-card > * {
	margin-bottom: 6px;
}

.user-ordered-items-card .user-order-id-container {
	font-weight: 600;
}

.user-ordered-items-card .user-payment-id-container {
	font-size: small;
}
.user-payment-id-container {
	color: grey;
}

.user-orders-container .user-price-container {
	color: grey;
	font-size: small;
}

.user-orders-container .user-products-card {
	display: flex;
	justify-content: safe;
	align-items: flex-start;
	color: grey;
	margin-bottom: 5px;
}

.user-ordered-items-card img {
	width: 90px;
}

.user-ordered-items-card .user-products-card .user-description {
	display: flex;
	flex-flow: column nowrap;
	align-self: start;
	margin: auto 10px;
}

.user-ordered-items-card .user-products-card .user-description > * {
	margin-bottom: 5px;
}
.user-ordered-items-card .user-products-card .user-name {
	color: black;
	font-size: small;
}

.user-ordered-items-card .user-products-card .user-qty {
	font-size: small;
}

.user-ordered-items-card .user-products-card .user-price {
	font-size: small;
}

.cancel-button {
	color: white;
	background-color: var(--primary-color);
	font-weight: 600;

	padding: 8px 16px;
	display: block;
	width: 50%;
	margin: 12px auto;
	border: 1px solid rgb(226, 226, 226);
	border-radius: 5px;
	direction: rtl;
}
.cancel-button:hover {
	/* color: white; */
	cursor: pointer;
	background-color: black;
}
