.expand-css {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 25vw;
}
.book-img-descr {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-right: 10vw;
}
.book-img-descr .image {
	object-fit: cover;
	/* height: 120px; */
	/* height: 75px; */
	height: 23vw;
	width: 18vw;
	/* background-color: wheat; */
	object-fit: fill;
	/* margin-left: 5px; */
}
.book-img-descr .descr {
	color: #333;
	align-self: center;
	justify-self: center;
	width: 100vw;
	margin-left: 10px;
}
.upload-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30vw;
}
@media (min-width: 768px) {
	.expand-css {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 25vw;
	}
	.book-img-descr {
		display: flex;
		flex-direction: row;
		height: 23vw;
	}
	.book-img-descr .descr {
		color: #333;
		align-self: center;
		justify-self: center;
		width: 40vw;
		margin-left: 20px;
	}
	.book-img-descr .image {
		/* height: 27vw; */
		/* object-fit: cover; */
		/* height: 120px; */
		height: 100%;
		object-fit: fill;
		width: 18vw;
		/* margin-left: 5px; */
	}
	.upload-button {
		width: 25vw;
	}
}
