.hero-video-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	height: 420px;
	min-width: 360px;
	background-color: black;
}

.hero-video {
	height: 100%;
	background-color: rgb(0, 0, 0);
	opacity: 0.5;
}
.hero-image {
	width: 300px;
	height: 110px;
}

.hero-video-container video {
	object-fit: cover;
}

.hero-text {
	position: absolute;
	color: white;
	top: 4em;
	text-align: center;
	align-self: center;
}
/* .hero-text img {
	width: 400px;
} */
.hero-text h1 {
	font-size: 3em;
	align-self: center;
	/* margin-bottom: 20px; */
}
.first-text {
	margin-top: 26px;
	font-size: 1em;
	opacity: 1;
}
.second-text {
	margin-top: 13px;
	font-size: 1em;
	opacity: 1;
}

.shop-now-btn {
	opacity: 0.9;
	font-weight: bolder;
	position: absolute;
	bottom: 2em;
	align-self: center;
	font-size: 1.1em;
	font-weight: 700;
	padding: 1.1rem 3rem;
	border: none;
	justify-self: stretch;
	border-radius: 40px;
	/* color: rgb(0, 0, 0); */
	/* color: rgb(253, 87, 87); */
	color: white;

	/* background-color: rgb(217, 217, 217); */
}

.shop-now-btn:hover {
	opacity: 1;
	cursor: pointer;
	background-position: left center; /* change the direction of the change here */
	color: rgb(217, 217, 217);
	text-decoration: none;
	background-color: rgb(255, 255, 255);
}
@media (min-width: 480px) {
	.hero-video-container {
		height: 600px;
		margin-bottom: 10vw;
	}
	.hero-text {
		top: 6em;
	}
	.hero-image {
		width: 100%;
		height: 150px;
	}
	.hero-text h1 {
		font-size: 4em;
		align-self: center;
		/* margin-bottom: 30px; */
	}
	.first-text {
		margin-top: 44px;
		font-size: 1.3em;
	}
	.second-text {
		margin-top: 22px;
		font-size: 1.3em;
	}
	.shop-now-btn {
		font-size: 2em;
		font-weight: 900;
		bottom: 2em;
	}
}
@media (min-width: 768px) {
	.hero-video-container {
		height: 725px;
		margin-bottom: 10vw;
	}
	.hero-text {
		top: 7em;
	}
	.hero-image {
		width: 100%;
		height: 190px;
	}
	.hero-text h1 {
		font-size: 5.5em;
		align-self: center;
		/* margin-bottom: 30px; */
	}
	.first-text {
		margin-top: 52px;
		font-size: 1.5em;
	}
	.second-text {
		margin-top: 26px;
		font-size: 1.5em;
	}
	.shop-now-btn {
		bottom: 3em;
		font-size: 2em;
		font-weight: 900;
	}
}
