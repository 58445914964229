.genre-modal-container {
	margin-top: 30px;
}

.genre-modal-container .genre-input-container {
	/* position: absolute; */
	/* inset: 0; */
	/* background-color: rgb(227, 235, 211); */
	background-color: #e3f2fd;
	margin: auto;
	width: 400px;
	height: 200px;
	text-align: center;
	border-radius: 10px;
}

.genre-input-container h2 {
	margin-top: 10px;
	padding-top: 15px;
	color: rgb(33, 33, 33);
	margin-bottom: 15px;
}

.genre-modal-container .genre-input-container input {
	padding: 8px 16px;
	display: block;
	width: 90%;
	margin: 12px auto;
	border: 1px solid rgb(226, 226, 226);
	border-radius: 5px;
	direction: rtl;
}

.genre-modal-container .submit {
	color: white;
	background-color: var(--primary-color);
	font-weight: 600;
	display: inline;
}

.genre-modal-container .submit:hover {
	cursor: pointer;
	background-color: black;
}

.submit:disabled {
	color: darkslateblue;
	background-color: azure !important;
	pointer-events: none;
}

@media (min-width: 768px) {
	.genre-modal-container .genre-input-container {
		width: 500px;
	}
}
