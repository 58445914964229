.profile-container {
	padding: 25px 10px;
}

.profile-container > h3 {
	margin-bottom: 20px;
	text-align: center;
}

.profile-details {
	margin-bottom: 20px;
}

.profile-details > * {
	margin-bottom: 10px;
}

.profile-details > * > :first-child {
	font-weight: 500;
}
.profile-details .name {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}
.profile-details .email {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}

@media (min-width: 768px) {
	.profile-container {
		padding: 30px;
	}
}
