.user-profile-container {
	width: 340px;
	margin: auto;
	margin-top: 100px;
	box-shadow: 0px 0px 23px 0px rgb(222, 222, 222);
	border-radius: 10px;
	direction: rtl;
}

.user-profile-heading {
	text-align: center;
	margin-bottom: 15px;
}

.link-container {
	border-radius: 10px 10px 0 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
	padding: 15px;
	background-color: rgb(236, 236, 236);
}

.link-container > a {
	color: black;
	text-decoration: none;

	font-weight: 600;
}

@media (min-width: 768px) {
	.user-profile-container {
		width: 550px;
		/* width: 40vw; */
		margin-bottom: 20px;
	}
}
