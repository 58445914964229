.cart-summary {
	width: 75vw;
	display: flex;
	flex-direction: row nowrap;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: center;
	/* background-color: rgb(242, 242, 242); */
	border-radius: 10px;
}
.cart-price-container {
	border-radius: 10px;
	/* padding: 20px; */
	display: flex;
	flex-flow: column nowrap;
	/* margin: 10px 0 0 0; */
	justify-self: center;
}

.cart-price-container a {
	text-decoration: none;
	color: white;
	background-color: var(--primary-color);
	align-self: center;
	padding: 10px 30px;
	border-radius: 10px;
	font-weight: 600;
	font-size: 1rem;
	margin: 40px auto;
}

.cart-price-container a:hover {
	background-color: black;
	cursor: pointer;
}

.cart-price-container .total {
	font-weight: 700;
	font-size: 35px;
	color: black;
}
.cart-price-container h1 {
	color: black;
	align-self: center;
}

.cart-price-container > * {
	/* margin-bottom: 10px; */
	display: flex;
	justify-content: space-between;
	color: grey;
}

.total-discount-container {
	color: rgb(55, 134, 18);
}

@media (min-width: 768px) {
	.cart-price-container {
		width: 75%;
		flex-flow: column nowrap;
		/* margin: 0 0 0 15px; */
		border-radius: 10px;
		/* padding: 25px; */
	}
}
