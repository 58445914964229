.ordered-products-container {
	display: flex;
	flex-direction: column;
}

.ordered1-product-card {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 5px;
	/* margin-top: 20px; */
	/* direction: rtl; */
}

.ordered1-product-card:first-child {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
}

.ordered1-product-card img {
	width: 75px;
	height: 75%;
	border-radius: 7px;
	background-color: rgb(241, 241, 241);
	margin-right: 10px;
}

.product-details-container {
	padding: 15px;
	margin: 15px 15px 0 15px;
	color: grey;
}

.product-details-container h1 {
	text-align: center;
	font-size: 40px;
	color: black;
}
/* 
@media (min-width: 768px) {
	.ordered1-product-card {
		justify-content: start;
		align-items: start;
	}
} */
