.book-modal-container {
	margin-top: 30px;
}

.book-modal-container .book-input-container {
	/* position: absolute; */
	/* inset: 0; */
	/* background-color: rgb(227, 235, 211); */
	background-color: #e3f2fd;
	margin: auto;
	width: 400px;
	height: 650px;
	text-align: center;
	border-radius: 10px;
}

.book-input-container h2 {
	margin-top: 10px;
	padding-top: 15px;
	color: rgb(33, 33, 33);
	margin-bottom: 15px;
}

.book-modal-container .book-input-container input,
.book-modal-container .book-input-container textarea,
.book-modal-container .book-input-container select {
	padding: 8px 16px;
	display: block;
	width: 90%;
	margin: 15px auto;
	border: 1px solid rgb(226, 226, 226);
	border-radius: 5px;
	direction: rtl;
}
.book-modal-container .book-input-container textarea {
	height: 85px;
}
.book-modal-container .submit {
	color: white;
	background-color: var(--primary-color);
	font-weight: 600;
	display: inline;
}

.book-modal-container .submit:hover {
	cursor: pointer;
	background-color: black;
}

.book-modal-container .button {
	color: white;
	background-color: var(--secondary-background-color);
	font-weight: 600;
	display: inline;
}
.book-modal-container .button:hover {
	/* color: white; */
	cursor: pointer;
	background-color: var(--secondary-background-color-onhover);
}

.book-modal-container .btn-container {
	display: flex;
	justify-content: center;
	padding: 0 20px;
	/* width: 90%; */
}

.book-modal-container .btn-container button {
	border: none;
	padding: 8px 16px;

	border-radius: 5px;
}

.book-modal-container .btn-container button:hover {
	cursor: pointer;
	background-color: var(--secondary-background-color-onhover);
}

.book-modal-container .btn-container button:last-child {
	flex-grow: 1;
	margin-left: 10px;
}
.file {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	height: 50px;
	margin: 12px auto;
	direction: rtl;
}
.file label {
	width: 200px;
	justify-self: start;
	justify-content: start;
}
.file input {
	/* width: 100px; */
	justify-self: end;
	border: none !important;
}
.selectGenre {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	height: 50px;
	margin: 12px auto;
	direction: rtl;
}
.selectGenre label {
	width: 200px;
	justify-self: start;
	justify-content: start;
}

.selectGenre select {
	/* justify-self: end; */
	text-align: center;
	text-align-last: center;
}
.file select option {
	text-align: center;
}

.file input:disabled,
.selectGenre select:disabled {
	/* width: 100px; */
	pointer-events: none;
}
.submit:disabled {
	color: darkslateblue;
	background-color: azure !important;
	pointer-events: none;
}

@media (min-width: 768px) {
	.book-modal-container .book-input-container {
		width: 500px;
	}
}
